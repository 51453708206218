<template>
    <div>
        <h1>
            Market Crashed Again!
        </h1>
        <small>
            No wait that was just us.
        </small>
        <p class="pt-4">
            Page could not be found please check the url and try again.
        </p>
    </div>
</template>
<script>
export default {
  name: 'PageNotFound'
}
</script>
